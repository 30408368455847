import React from "react";
import { IoIosStar } from "react-icons/io";


const Review = () => {
    return (
        <div className="mt-5">
            <div className="mx-2 bg-white border rounded-2xl">
                <div className="flex justify-between mx-2 my-2">
                    <div className=" flex items-center md:ml-24 mr-3">
                        <h4 className="text-sm md:text-3xl font-bold whitespace-nowrap">Write a Review</h4>
                    </div>
                    <div className="h-32">
                        <img
                            src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1728467717/Capture_vmjtc4.png"
                            className="h-full"
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                <div className="mx-5 mt-5 bg-white border w-64 lg:w-60 h-72 rounded-2xl">
                    <div className="mx-5 mt-5">
                        <div className="flex">
                            <div>
                                <img
                                    src="https://t4.ftcdn.net/jpg/05/80/48/17/360_F_580481753_1PdWMpjTkM6zlV71wVWCuiUbooIurjI6.jpg"
                                    className="w-20 h-20 object-cover rounded-full"
                                />
                            </div>
                            <div className="mt-5 ml-5">
                                <p>My Name</p>
                                <i className="flex text-yellow-500">
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                </i>
                            </div>
                        </div>
                        <div className="flext justify-items-center mt-10">
                            <p>
                                To make the Review component responsive for all screen sizes,
                                we can adjust the layout using Tailwind CSS classes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mx-5 mt-5 bg-white border w-64 h-72 rounded-2xl">
                    <div className="mx-5 mt-5">
                        <div className="flex">
                            <div>
                                <img
                                    src="https://t4.ftcdn.net/jpg/05/80/48/17/360_F_580481753_1PdWMpjTkM6zlV71wVWCuiUbooIurjI6.jpg"
                                    className="w-20 h-20 object-cover rounded-full"
                                />
                            </div>
                            <div className="mt-5 ml-5">
                                <p>My Name</p>
                                <i className="flex text-yellow-500">
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                </i>
                            </div>
                        </div>
                        <div className="flext justify-items-center mt-10">
                            <p>
                                To make the Review component responsive for all screen sizes,
                                we can adjust the layout using Tailwind CSS classes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mx-5 mt-5 bg-white border w-64 h-72 rounded-2xl">
                    <div className="mx-5 mt-5">
                        <div className="flex">
                            <div>
                                <img
                                    src="https://t4.ftcdn.net/jpg/05/80/48/17/360_F_580481753_1PdWMpjTkM6zlV71wVWCuiUbooIurjI6.jpg"
                                    className="w-20 h-20 object-cover rounded-full"
                                />
                            </div>
                            <div className="mt-5 ml-5">
                                <p>My Name</p>
                                <i className="flex text-yellow-500">
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                </i>
                            </div>
                        </div>
                        <div className="flext justify-items-center mt-10">
                            <p>
                                To make the Review component responsive for all screen sizes,
                                we can adjust the layout using Tailwind CSS classes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mx-5 mt-5 bg-white border w-64 h-72 rounded-2xl">
                    <div className="mx-5 mt-5">
                        <div className="flex">
                            <div>
                                <img
                                    src="https://t4.ftcdn.net/jpg/05/80/48/17/360_F_580481753_1PdWMpjTkM6zlV71wVWCuiUbooIurjI6.jpg"
                                    className="w-20 h-20 object-cover rounded-full"
                                />
                            </div>
                            <div className="mt-5 ml-5">
                                <p>My Name</p>
                                <i className="flex text-yellow-500">
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                    <IoIosStar />
                                </i>
                            </div>
                        </div>
                        <div className="flext justify-items-center mt-10">
                            <p>
                                To make the Review component responsive for all screen sizes,
                                we can adjust the layout using Tailwind CSS classes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex float-end mr-10">
                <a href="#" className="text-cyan-600 text-sm font-bold hover:underline mt-3">View All</a>
            </div>
        </div>
    )
};
export default Review;