import React from 'react';
import Hero from './hero';
import ProductIndex from '../Product';
import BrandList from './brandList';
import Review from './review';
import MostViewProducts from '../Product/mostViewProduct';
import LatestNews from './news';
import YourStory from './story';
import News from './test';

const Home = ({ isMenuOpen }) => {
    return (
        <>
            <div className='mb-10'>
                {/* <!-- Hero section --> */}
                <div className='mt-24'>
                    <Hero />
                </div>

                {/* Product Index file*/}
                <div className='mt-5 px-2'>
                    <ProductIndex isMenuOpen={isMenuOpen} />
                </div>

                {/* Brand List */}
                <BrandList />

                {/* Review Sections */}
                <Review />

                {/* Most Views Product */}
                <MostViewProducts />

                {/* Latest News */}
                <div className='mx-10'>
                    <LatestNews />
                </div>

                {/* <News/> */}

                {/* Your Story */}
                <YourStory />
            </div>
        </>
    )
};
export default Home;