import React from "react";

const CarDetails = () => {
    return (
        <>
            <div className="mt-52 mx-5 md:mt-24">
                {/* Hero Section */}
                <div className="w-full p-2">
                    <div className="bg-white rounded-lg overflow-hidden shadow-lg">

                        <div className="mb-5 flex justify-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mx-4 md:mx-24 items-center">
                            <div className="text-center md:text-left text-lg md:text-2xl">
                                <h4 className="font-semibold">DLIBA6895</h4>
                                <h4 className="font-semibold">Rasmiranjan Parida</h4>
                                <p className="text-gray-600">Hyundai Creta</p>
                            </div>
                            {/* Background Image */}
                            <div className="w-full md:w-auto">
                                <img
                                    src="https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60"
                                    className="md:w-[120%] h-auto lg:h-80 max-w-xs md:max-w-none rounded-lg object-cover"
                                    alt="Hyundai Creta"
                                />
                            </div>
                        </div>


                        {/* Input and Button Section */}
                        <div className="flex space-x-10 mx-16 mb-5 md:mx-44 lg:mx-[30%]">
                            {/* Input Placeholder */}
                            <div className="h-12 w-full rounded-lg shadow-lg bg-white border border-orange-500"></div>

                            {/* Button Placeholder */}
                            <div className="h-12 w-full rounded-lg shadow-lg bg-white border border-orange-500"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CarDetails;
