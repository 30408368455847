
import React from "react";

const dummyProducts = [
    {
        id: 1,
        name: "Audi Q3",
        price: "₹ 11.00 - ₹ 20.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60",
        islaunch: 'Newly Launch'
    },
    {
        id: 2,
        name: "BMW X1",
        price: "₹ 12.00 - ₹ 22.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1675079349.jpg?w=600&q=60?w=1920&q=60",
        islaunch: ''
    },
    {
        id: 3,
        name: "Mercedes GLA",
        price: "₹ 15.00 - ₹ 25.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702531230.jpg?w=600&q=60?w=1920&q=60",
        islaunch: ''
    },
    {
        id: 4,
        name: "Volvo XC40",
        price: "₹ 18.00 - ₹ 28.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60?w=1920&q=60",
        islaunch: 'Newly Launch'
    },
    {
        id: 5,
        name: "Jaguar E-Pace",
        price: "₹ 20.00 - ₹ 30.00 lakh *",
        image: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661240340.jpg?w=600&q=60?w=1920&q=60",
        islaunch: ''
    },
];

const MostViewProducts = () => {
    return (
        <>
        <div className="mt-10 mx-16 mb-5">
            <h4 className="text-xl font-bold">Most Viewed</h4>
        </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 px-4 md:px-8 lg:px-10 mx-auto">
                {dummyProducts.map((product) => (
                    <div
                        key={product.id}
                        className="bg-white border shadow-lg rounded-lg flex flex-col p-1 w-full text-sm cursor-pointer"
                    >
                        <a className="block flex-grow">
                            <div>
                                <div className="overflow-hidden bg-white mx-auto mb-2 rounded-lg">
                                        <a
                                            href="#"
                                            className={`${product.islaunch ? 'bg-red-700' : ''}  text-white text-xs ml-2 mt-4 px-2 rounded-md`}
                                        >
                                            {product.islaunch ? product.islaunch : ''}
                                        </a>
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="transform hover:scale-125 transition-transform duration-300"
                                        style={{ width: "100%", height: "50%" }}
                                    />
                                </div>
                                <div className="ml-2">
                                    <h3 className="font-semibold mb-1">{product.name}</h3>
                                    <p className="mb-1 text-[12px]">{product.price}</p>
                                </div>
                            </div>
                        </a>
                        <div className="text-center mt-2">
                            <button className="text-red-700 border border-red-700 bg-transparent py-1 font-bold w-full rounded transition mt-2 hover:bg-red-700 hover:text-white">
                                View Details
                            </button>
                        </div>
                    </div>
                ))}
            </div>

        </>
    );
};

export default MostViewProducts;