import React from 'react'

const Navbar = () => {
    return (
        <div>
            {/* <!-- Navigation Links --> */}
            <ul className="hidden space-x-3 lg:space-x-0 lg:flex items-center font-bold">
                <li className="flex">
                    <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1">Car</a>
                </li>
                <li className="flex">
                    <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1">Bike</a>
                </li>
                <li className="flex">
                    <a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1">RTO</a>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;