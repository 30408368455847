import React from 'react';
import Hero from '../Home/hero';
import ProductIndex from '../Product';
import BrandList from '../Home/brandList';
import Review from '../Home/review';
import MostViewProducts from '../Product/mostViewProduct';
import LatestNews from '../Home/news';
import ProductsCompare from '../Product/productCompare';
import ProductDetails from '../Product/productDetails';

const DetailsPage = ({ isMenuOpen }) => {
    return (
        <>
            <div className='mb-10'>
                {/* <!-- Hero section --> */}
                {/* <div className='mt-24'>
                    <Hero />
                </div> */}

                {/* Product Details */}
                <ProductDetails />

                {/* Compare Car */}
                <div className=''>
                    <h4 className='mx-10 text-xl font-bold'>Similar Car Comparions</h4>
                    <ProductsCompare />
                </div>


                {/* Brand List */}
                <BrandList />


                {/* Review Sections */}
                <Review />


                {/* Most Views Product */}
                <MostViewProducts />


                {/* Latest News */}
                <div className='mx-10'>
                    <LatestNews />
                </div>


                {/* Product Index file*/}
                {/* <div className='mt-5 px-2'>
                    {/* <ProductIndex isMenuOpen={isMenuOpen} />
                </div> */}

            </div>
        </>
    )
};
export default DetailsPage;