import React, { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const YourStory = () => {
    // Dummy data array
    const stories = [
        { id: 1, name: "Your Story 1" },
        { id: 2, name: "Your Story 2" },
        { id: 3, name: "Your Story 3" },
        { id: 4, name: "Your Story 4" },
        { id: 5, name: "Your Story 5" },
        { id: 6, name: "Your Story 6" },
        { id: 7, name: "Your Story 7" }
    ];

    // Ref to control scrolling
    const scrollRef = useRef(null);

    // Scroll left function
    const scrollLeft = () => {
        scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
    };

    // Scroll right function
    const scrollRight = () => {
        scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    };

    return (
        <div className="mx-5 mt-10">
            <div className="text-center mb-5">
                <h4 className="text-xl font-bold">Featured In</h4>
            </div>

            {/* Carousel Wrapper */}
            <div className="flex items-center justify-between">
                {/* Left Arrow */}
                <button onClick={scrollLeft} className="p-2 text-gray-400">
                    <FaChevronLeft />
                </button>

                {/* Cards Container */}
                <div
                    ref={scrollRef}
                    className="flex overflow-hidden scrollbar-hide space-x-4 w-full px-4"
                    style={{ scrollSnapType: "x mandatory" }}
                >
                    {stories.map((story) => (
                        <div
                            key={story.id}
                            className="bg-red-300 w-52 h-10 flex-shrink-0 flex items-center justify-center rounded-md"
                            style={{ scrollSnapAlign: "start" }}
                        >
                            <p className="mx-3 text-center">{story.name}</p>
                        </div>
                    ))}
                </div>

                {/* Right Arrow */}
                <button onClick={scrollRight} className="p-2 text-gray-400">
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
};

export default YourStory;
