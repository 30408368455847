import React, { useState } from 'react';
import { IoMenuSharp } from 'react-icons/io5';
import { IoMdClose } from "react-icons/io";

const MobileMenu = ({ setIsMenuOpen }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle menu visibility
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setIsMenuOpen(isOpen);
    };

    return (
        <>
            {/* Mobile Menu Button */}
            <button title="Open menu" type="button" className="p-4 lg:hidden" onClick={toggleMenu}>
                <IoMenuSharp className="w-6 h-6" />
            </button>

            {/* Mobile Menu (full screen) */}
            {isOpen && (
                <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-orange-500 h-screen w-screen">
                    <ul className="flex flex-col space-y-4 text-center text-sm font-bold">
                        <li>
                            <a href="#" className="block px-4 py-2">Car</a>
                        </li>
                        <li>
                            <a href="#" className="block px-4 py-2">Bike</a>
                        </li>
                        <li>
                            <a href="#" className="block px-4 py-2">RTO</a>
                        </li>
                    </ul>

                    {/* Close button */}
                    <button onClick={toggleMenu} className="absolute top-4 right-4 p-2">
                        <IoMdClose className="w-6 h-6" />
                    </button>
                </div>
            )}
        </>
    );
};

export default MobileMenu;
