import React from "react";
import Searchbox from "./searchbox";
import Navbar from "./navbar";
import Login from "./login";
import Mobnavbar from "./mobnavbar";

const Headers = ({ setIsMenuOpen }) => {
    return (
        <div>
            {/* Header */}
            <header className="fixed top-0 left-0 w-full p-4 bg-white shadow-lg z-50 overflow-hidden">
                <div className="container mx-auto flex items-center justify-between">

                    {/* <!-- Mobile Menu (hidden by default) --> */}
                    <Mobnavbar setIsMenuOpen={setIsMenuOpen}/>

                    {/* <!-- Logo aligned to the left --> */}
                    <a href="#" className="flex items-center p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 32 32" className="w-8 h-8 text-black">
                            <path d="M27.912 7.289l-10.324-5.961c-0.455-0.268-1.002-0.425-1.588-0.425s-1.133 0.158-1.604 0.433l0.015-0.008-10.324 5.961c-0.955 0.561-1.586 1.582-1.588 2.75v11.922c0.002 1.168 0.635 2.189 1.574 2.742l0.016 0.008 10.322 5.961c0.455 0.267 1.004 0.425 1.59 0.425 0.584 0 1.131-0.158 1.602-0.433l-0.014 0.008 10.322-5.961c0.955-0.561 1.586-1.582 1.588-2.75v-11.922c-0.002-1.168-0.633-2.189-1.573-2.742zM27.383 21.961c0 0.389-0.211 0.73-0.526 0.914l-0.004 0.002-10.324 5.961c-0.152 0.088-0.334 0.142-0.53 0.142s-0.377-0.053-0.535-0.145l0.005 0.002-10.324-5.961c-0.319-0.186-0.529-0.527-0.529-0.916v-11.922c0-0.389 0.211-0.73 0.526-0.914l0.004-0.002 10.324-5.961c0.152-0.090 0.334-0.143 0.53-0.143s0.377 0.053 0.535 0.144l-0.006-0.002 10.324 5.961c0.319 0.185 0.529 0.527 0.529 0.916z"></path>
                        </svg>
                        <span className="ml-2">Logo</span>
                    </a>

                    {/* <!-- Search bar in the center --> */}
                    <div className="hidden md:flex flex-1 justify-center">
                        <Searchbox />
                    </div>

                    {/* <!-- Navigation Links, displayed in the center --> */}
                    <div className="hidden lg:flex justify-center">
                        <Navbar />
                    </div>

                    {/* <!-- Login to the right --> */}
                    <div className="flex ml-2 sm:ml-4 md:ml-6 lg:ml-1 xl:ml-20">
                        <Login />
                    </div>

                </div>

                {/* <!-- Mobile Search bar (visible on small screens) --> */}
                <div className="md:hidden mt-4">
                    <Searchbox />
                </div>
            </header>
        </div>
    );
};

export default Headers;
