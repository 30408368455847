import React from 'react';
import { IoIosSearch } from "react-icons/io";

const Searchbox = () => {
    return (
        <>
            {/* <!-- Search bar --> */}
            <div className="relative flex-1 mx-4">
                <input
                    type="search"
                    name="Search"
                    placeholder="Search..."
                    // Responsive width for different screen sizes
                    className="w-full sm:w-[24rem] md:w-[30rem] lg:w-[40rem] xl:w-[50rem] py-3 px-4 text-sm rounded-xl border border-black focus:outline-none"
                />
                <span className="absolute inset-y-0 right-4 lg:right-8 md:right-14 flex items-center">
                    <button type="submit" title="Search" className="p-1 focus:outline-none focus:ring">
                        <IoIosSearch className='text-2xl' />
                    </button>
                </span>
            </div>

        </>
    )
}

export default Searchbox;
