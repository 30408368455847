import React from "react";

const dummyProducts = [
    {
        id: 1,
        name1: "Audi Q3",
        price1: "₹ 11.00 - ₹ 20.00 Lakh*",
        image1: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60",
        name2: "BMW X1",
        price2: "₹ 12.00 - ₹ 22.00 Lakh*",
        image2: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1675079349.jpg?w=600&q=60",
    },
    {
        id: 2,
        name1: "Mercedes GLA",
        price1: "₹ 15.00 - ₹ 25.00 Lakh*",
        image1: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702531230.jpg?w=600&q=60",
        name2: "Volvo XC40",
        price2: "₹ 18.00 - ₹ 28.00 Lakh*",
        image2: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60",
    },
    {
        id: 3,
        name1: "Jaguar E-Pace",
        price1: "₹ 20.00 - ₹ 30.00 Lakh*",
        image1: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661240340.jpg?w=600&q=60",
        name2: "Audi Q5",
        price2: "₹ 22.00 - ₹ 32.00 Lakh*",
        image2: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1702454870.jpg?w=600&q=60?w=1920&q=60",
    },
    // {
    //     id: 4,
    //     name1: "BMW X3",
    //     price1: "₹ 30.00 - ₹ 40.00 Lakh*",
    //     image1: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1663160816.jpg?w=600&q=60?w=1920&q=60",
    //     name2: "Lexus NX",
    //     price2: "₹ 32.00 - ₹ 42.00 Lakh*",
    //     image2: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661230752.jpg?w=600&q=60?w=1920&q=60",
    // },
    // {
    //     id: 5,
    //     name1: "Range Rover Evoque",
    //     price1: "₹ 50.00 - ₹ 60.00 Lakh*",
    //     image1: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661231454.jpg?w=600&q=60?w=1920&q=60",
    //     name2: "Porsche Macan",
    //     price2: "₹ 55.00 - ₹ 65.00 Lakh*",
    //     image2: "https://images.91wheels.com/assets/b_images/main/models/profile/profile1661243825.jpg?w=600&q=60?w=1920&q=60",
    // },
];

const ProductsCompare = () => {
    return (
        <div className="flex flex-wrap justify-center mt-5 mb-5 gap-4">
            {dummyProducts.map((product) => (
                <div key={product.id} className="bg-white border rounded-lg p-4 w-full max-w-sm shadow-lg cursor-pointer">
                    <div className="relative flex">
                        <img
                            src={product.image1}
                            className="transform hover:scale-125 transition-transform duration-300 w-1/2 rounded-lg object-cover"
                            alt={product.name1}
                        />
                        <img
                            src={product.image2}
                            className="transform hover:scale-125 transition-transform duration-300 w-1/2 rounded-lg object-cover"
                            alt={product.name2}
                        />
                        <div className="absolute inset-0 flex justify-center items-center z-10">
                            <div className="bg-black text-white rounded-full w-12 h-12 flex items-center justify-center font-bold text-sm">
                                VS
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <div className="text-center">
                            <h3 className="font-semibold text-lg">{product.name1}</h3>
                            <p className="text-sm text-gray-700">{product.price1}</p>
                        </div>
                        <div className="text-center">
                            <h3 className="font-semibold text-lg">{product.name2}</h3>
                            <p className="text-sm text-gray-700">{product.price2}</p>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button className="text-red-600 border border-red-600 w-full py-2 px-8 font-bold rounded-full hover:bg-red-600 hover:text-white transition">
                            View Details
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProductsCompare;
