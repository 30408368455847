import React, { useState } from "react";
import { SiSuzuki } from "react-icons/si"; // Import icons for other brands as needed

const brands = [
    { id: 1, name: "Suzuki", icon: <SiSuzuki className="text-3xl" /> },
    { id: 2, name: "Toyota", icon: <SiSuzuki className="text-3xl" /> }, // Replace with actual icon
    { id: 3, name: "Honda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    { id: 4, name: "Nissan", icon: <SiSuzuki className="text-3xl" /> }, // Replace with actual icon
    { id: 5, name: "Mazda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    { id: 6, name: "Mazda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    { id: 7, name: "Mazda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    { id: 8, name: "Mazda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    { id: 9, name: "Mazda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    { id: 10, name: "Mazda", icon: <SiSuzuki className="text-3xl" /> },  // Replace with actual icon
    // Add more brands as needed
];

const BrandList = () => {
    const [activeTab, setActiveTab] = useState("Car"); // State for active tab

    return (
        <div className="mx-2 mt-10">
            <div className="ml-5 text-xl font-bold">Search By Brands</div>
            <div className="mt-3">
                <div className="border-b border-gray-300 mx-3"> {/* Main border */}
                    <div className="flex space-x-5 md:space-x-10 lg:space-x-20">
                        <h4
                            className={` cursor-pointer relative ${activeTab === "Car" ? "after:w-full" : "after:w-0"} after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:bg-orange-300 after:transition-all after:duration-300 after:transform after:origin-left`}
                            onClick={() => setActiveTab("Car")}
                        >
                            <span className="ml-3">Car</span>
                        </h4>
                        <h4
                            className={` cursor-pointer relative ${activeTab === "Bike" ? "after:w-full" : "after:w-0"} after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:bg-orange-300 after:transition-all after:duration-300 after:transform after:origin-left`}
                            onClick={() => setActiveTab("Bike")}
                        >
                            Bike
                        </h4>
                        <h4
                            className={` cursor-pointer relative ${activeTab === "Heavy" ? "after:w-full" : "after:w-0"} after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:bg-orange-300 after:transition-all after:duration-300 after:transform after:origin-left`}
                            onClick={() => setActiveTab("Heavy")}
                        >
                            Heavy
                        </h4>
                        <h4
                            className={` cursor-pointer relative ${activeTab === "Commercial" ? "after:w-full" : "after:w-0"} after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[2px] after:bg-orange-300 after:transition-all after:duration-300 after:transform after:origin-left`}
                            onClick={() => setActiveTab("Commercial")}
                        >
                            Commercial
                        </h4>
                    </div>
                </div>
                <section className="bg-gray-300 text-gray-100 mt-1 rounded-2xl border border-gray-300 overflow-x-auto"> {/* Main border with horizontal scroll */}
                    <div className="p-8 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-10 gap-4"> {/* Center the items */}
                        {brands.map((brand) => (
                            <div key={brand.id} className="bg-white w-20 h-20 rounded-full flex flex-col items-center justify-center text-center mb-4 flex-shrink-0">
                                <i className="text-red-700 text-2xl">{brand.icon}</i>
                                <h4 className="text-xs sm:text-sm text-blue-700 font-bold">{brand.name}</h4>
                            </div>
                        ))}
                    </div>
                    <div className=" text-cyan-400 float-end text-md mr-5 mb-5 cursor-pointer">
                        <a>View All</a>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default BrandList;