import React from "react";
import CategoryProduct from "./categoryProduct";
import Products from "./productCard";
import OurServices from "./ourServices";


const ProductIndex = ({ isMenuOpen }) => {
    return (
        <>

            {/* Category Produccts */}
            <CategoryProduct isMenuOpen={isMenuOpen} />

            {/* Our Services */}
            <OurServices/>

            {/* Products cards */}
            <Products/>

        </>
    )
};
export default ProductIndex;