import React, { useState } from "react";
import { IoMdHeartEmpty } from "react-icons/io";
import { FaCodeCompare } from "react-icons/fa6";
import { PiPrinter } from "react-icons/pi";
import { CiShare2 } from "react-icons/ci";
import { LiaImageSolid } from "react-icons/lia";
import { BsPostcard } from "react-icons/bs";
import Products from "./productCard";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";

const ProductDetails = () => {
    const [open, setOpen] = useState(null);

    const toggleAccordion = (index) => {
        setOpen(open === index ? null : index);
    };


    return (
        <>
            <div className="bg-gray-100 mx-5 rounded-xl mt-24">
                <div className="container py-4">
                    <div className="flex flex-wrap">
                        {/* Product Images */}
                        <div className="w-full md:w-[55%] lg:w-[70%] rounded-3xl shadow-md bg-white">
                            <img
                                src="https://images.91wheels.com/assets/b_images/main/models/profile/profile1702455656.jpg?w=600&q=60?w=1920&q=60"
                                alt="Product"
                                className="w-full h-96 mt-3"
                            />
                            <div className="text-center space-x-10 mb-3 -mt-5">
                                <a href="#" className="inline-flex items-center gap-1">
                                    <LiaImageSolid className="text-xl" />
                                    <span>Colors</span>
                                </a>
                                <a href="#" className="inline-flex items-center gap-1">
                                    <LiaImageSolid className="text-xl" />
                                    <span>Images</span>
                                </a>
                            </div>
                        </div>

                        {/* Product Details */}
                        <div className="w-full md:w-[30%]">
                            <div className="ms-5">
                                <h2 className="text-3xl font-bold mb-2">Honda Nios</h2>
                                <div className="mb-4">
                                    <span className="text-2xl font-bold mr-2">₹ 10.98 Lakh</span>
                                    <div className="text-xs font-bold">16.94 lakh</div>
                                    <p className="text-blue-700">Ex Showroom Price Breakup</p>
                                </div>
                            </div>

                            <div className="flex space-x-4 mb-6 mt-12 text-xs font-bold px-5">
                                <button className="bg-gray-300 flex gap-2 items-center text-black px-6 py-2 rounded-md hover:bg-gray-400 focus:outline-none">
                                    EMI Calculator
                                </button>
                                <button className="bg-orange-500 flex gap-2 items-center text-white px-6 py-2 rounded-md hover:bg-orange-600 focus:outline-none">
                                    Enquiry Now
                                </button>
                            </div>

                            <div className="w-full">
                                <div className="flex space-x-10 px-10 py-4 mb-6 mt-8 text-xs font-bold bg-gray-300">
                                    <a href="#" className="inline-flex items-center gap-2 whitespace-nowrap">
                                        <span className="text-xl"><BsPostcard /></span>
                                        Apply For Loan
                                    </a>
                                    <a href="#" className="inline-flex items-center gap-2 whitespace-nowrap">
                                        <span className="text-xl"><BsPostcard /></span>
                                        Apply For Insurance
                                    </a>
                                </div>
                            </div>

                            <div className="flex space-x-4 mx-4 py-4 mb-6 mt-5 text-xs font-bold">
                                <a href="#" className="inline-flex items-center gap-1">
                                    <IoMdHeartEmpty className="text-xl" />
                                    <span>SAVE</span>
                                </a>
                                <a href="#" className="inline-flex items-center gap-1">
                                    <FaCodeCompare className="text-xl" />
                                    <span>COMPARE</span>
                                </a>
                                <a href="#" className="inline-flex items-center gap-1">
                                    <PiPrinter className="text-xl" />
                                    <span>BROCHURE</span>
                                </a>
                                <a href="#" className="inline-flex items-center gap-1">
                                    <CiShare2 className="text-xl" />
                                    <span>SHARE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5 mx-5 mb-5">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-5">
                    <div className="col-span-12 md:col-span-8">
                        <div
                            className="flex justify-center items-center bg-gray-200 rounded-md 
                        py-1 w-full space-x-2 sm:space-x-4 text-sm md:space-x-8 lg:space-x-24 whitespace-nowrap">
                            <a>Overview</a>
                            <a className="bg-white p-2 rounded-lg">Specifications</a>
                            <a>Variant</a>
                            <a>Price By City</a>
                            <a>Review</a>
                        </div>


                        {/* accordion */}
                        <div>
                            <div className="space-y-4">
                                {/* Accordion Item 1 */}
                                <div className="border-b border-slate-200">
                                    <button
                                        onClick={() => toggleAccordion(1)}
                                        className="w-full flex justify-between items-center py-5 text-slate-800"
                                    >
                                        <span>What is Material Tailwind?</span>
                                        <span
                                            className={`text-slate-800 transition-transform duration-300 ${open === 1 ? '' : 'rotate-180'
                                                }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 1 ? 'max-h-screen' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="pb-5 text-sm text-slate-500">
                                            Material Tailwind is a framework that enhances Tailwind CSS with additional styles and components.
                                        </div>
                                    </div>
                                </div>

                                {/* Accordion Item 2 */}
                                <div className="border-b border-slate-200">
                                    <button
                                        onClick={() => toggleAccordion(2)}
                                        className="w-full flex justify-between items-center py-5 text-slate-800"
                                    >
                                        <span>How to use Material Tailwind?</span>
                                        <span
                                            className={`text-slate-800 transition-transform duration-300 ${open === 2 ? '' : 'rotate-180'
                                                }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 2 ? 'max-h-screen' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="pb-5 text-sm text-slate-500">
                                            You can use Material Tailwind by importing its components into your Tailwind CSS project.
                                        </div>
                                    </div>
                                </div>

                                {/* Accordion Item 3 */}
                                <div className="border-b border-slate-200">
                                    <button
                                        onClick={() => toggleAccordion(3)}
                                        className="w-full flex justify-between items-center py-5 text-slate-800"
                                    >
                                        <span>What can I do with Material Tailwind?</span>
                                        <span
                                            className={`text-slate-800 transition-transform duration-300 ${open === 3 ? '' : 'rotate-180'
                                                }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 3 ? 'max-h-screen' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="pb-5 text-sm text-slate-500">
                                            Material Tailwind allows you to quickly build modern, responsive websites with a focus on design.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* card  */}
                        <div className="mt-5 mb-10">
                            <div className="flex flex-wrap md:space-x-2">
                                <div className="bg-green-100 shadow-lg w-full sm:w-[48%] h-w p-5 rounded-lg mb-2 sm:mb-0">
                                    <div className="inline-flex items-center mb-3 text-xl">
                                        <h4 className="flex items-center font-semibold">
                                            <span className="mr-1 text-2xl text-green-500">
                                                <AiOutlineLike />
                                            </span>
                                            Pros
                                        </h4>
                                    </div>
                                    <div className="text-sm">
                                        <nav>
                                            <ul className="list-disc pl-4">
                                                <li className="mb-2">
                                                    Material Tailwind is a framework that enhances Tailwind CSS with additional styles and components.
                                                </li>
                                                <li className="mb-2">
                                                    Material Tailwind provides utility classes for building responsive designs easily.
                                                </li>
                                                <li className="mb-2">
                                                    It includes pre-designed components for faster development.
                                                </li>
                                                <li className="mb-2">
                                                    You can customize the components to fit your design needs.
                                                </li>
                                                <li>
                                                    Material Tailwind is ideal for creating modern, user-friendly interfaces.
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="bg-pink-100 shadow-lg w-full sm:w-[48%] h-w p-5 rounded-lg mb-2 sm:mb-0">
                                    <div className="inline-flex items-center mb-3 text-xl">
                                        <h4 className="flex items-center font-semibold">
                                            <span className="mr-1 text-2xl text-red-500">
                                                <AiOutlineDislike />
                                            </span>
                                            Cons
                                        </h4>
                                    </div>
                                    <div className="text-sm">
                                        <nav>
                                            <ul className="list-disc pl-4">
                                                <li className="mb-2">
                                                    Material Tailwind is a framework that enhances Tailwind CSS with additional styles and components.
                                                </li>
                                                <li className="mb-2">
                                                    Material Tailwind provides utility classes for building responsive designs easily.
                                                </li>
                                                <li className="mb-2">
                                                    It includes pre-designed components for faster development.
                                                </li>
                                                <li className="mb-2">
                                                    You can customize the components to fit your design needs.
                                                </li>
                                                <li>
                                                    Material Tailwind is ideal for creating modern, user-friendly interfaces.
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* accordion */}
                        <div className="mt-5 mb-10">
                            <div className="space-y-4">
                               
                                    <div className="flex justify-between">
                                        <div>
                                            <p className="text-xs">Honda Nios all</p>
                                            <h4 className="text-xl font-semibold">Variant</h4>
                                        </div>
                                        <div className="me-24">
                                            <h4 className="text-xl font-semibold">Price</h4>
                                        </div>
                                    </div>

                                {/* Accordion Item 1 */}
                                <div className="border-b border-slate-200">
                                    <button
                                        onClick={() => toggleAccordion(1)}
                                        className="w-full flex justify-between items-center py-5 text-slate-800"
                                    >
                                        <span>What is Material Tailwind?</span>
                                        <span
                                            className={`text-slate-800 transition-transform duration-300 ${open === 1 ? '' : 'rotate-180'
                                                }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 1 ? 'max-h-screen' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="pb-5 text-sm text-slate-500">
                                            Material Tailwind is a framework that enhances Tailwind CSS with additional styles and components.
                                        </div>
                                    </div>
                                </div>

                                {/* Accordion Item 2 */}
                                <div className="border-b border-slate-200">
                                    <button
                                        onClick={() => toggleAccordion(2)}
                                        className="w-full flex justify-between items-center py-5 text-slate-800"
                                    >
                                        <span>How to use Material Tailwind?</span>
                                        <span
                                            className={`text-slate-800 transition-transform duration-300 ${open === 2 ? '' : 'rotate-180'
                                                }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 2 ? 'max-h-screen' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="pb-5 text-sm text-slate-500">
                                            You can use Material Tailwind by importing its components into your Tailwind CSS project.
                                        </div>
                                    </div>
                                </div>

                                {/* Accordion Item 3 */}
                                <div className="border-b border-slate-200">
                                    <button
                                        onClick={() => toggleAccordion(3)}
                                        className="w-full flex justify-between items-center py-5 text-slate-800"
                                    >
                                        <span>What can I do with Material Tailwind?</span>
                                        <span
                                            className={`text-slate-800 transition-transform duration-300 ${open === 3 ? '' : 'rotate-180'
                                                }`}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 16 16"
                                                fill="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                    </button>
                                    <div
                                        className={`overflow-hidden transition-all duration-300 ease-in-out ${open === 3 ? 'max-h-screen' : 'max-h-0'
                                            }`}
                                    >
                                        <div className="pb-5 text-sm text-slate-500">
                                            Material Tailwind allows you to quickly build modern, responsive websites with a focus on design.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <div className="flex justify-center md:justify-start">
                            <span className="text-xl">Product</span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ProductDetails;
