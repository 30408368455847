import React from 'react';
import Hero from '../Home/hero';
import ProductIndex from '../Product';
import BrandList from '../Home/brandList';
import Review from '../Home/review';
import MostViewProducts from '../Product/mostViewProduct';
import LatestNews from '../Home/news';
import ProductsCompare from '../Product/productCompare';

const CarPage = ({ isMenuOpen }) => {
    return (
        <>
            <div className='mb-10'>
                {/* <!-- Hero section --> */}
                <div className='mt-24'>
                    <Hero />
                </div>

                {/* Product Index file*/}
                <div className='mt-5 px-2'>
                    <ProductIndex isMenuOpen={isMenuOpen} />
                </div>

                {/* Brand List */}
                <BrandList />

                {/* Most Views Product */}
                <MostViewProducts />

                {/* Compare Car */}
                <div className='mt-10'>
                    <h4 className='mx-10 text-xl font-bold'>Popular Car Comparions</h4>
                    <ProductsCompare />
                </div>

                {/* Review Sections */}
                <Review />

                {/* Latest News */}
                <div className='mx-10'>
                    <LatestNews />
                </div>

            </div>
        </>
    )
};
export default CarPage;