import React, { useState } from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Headers from "./Components/Header";
import Home from "./Components/Home";
import ProductDetails from "./Components/Product/productDetails";
import Profile from "./Components/profile/profile";
import ProductCrousel from "./Components/Product/productCrousel";
import ProductsCompare from "./Components/Product/productCompare";
import CarDetails from "./Components/Product/carDetails";
import LoginForm from "./Components/profile/login";
import RegisterForm from "./Components/profile/register";
import OtpCode from "./Components/profile/otp";
import CarPage from "./Components/CarPage";
import DetailsPage from "./Components/DetailsPage";


const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  return (
    <Router>
      <Headers setIsMenuOpen={setIsMenuOpen} />
      <Routes>
        <Route exact path="/" element={<Home isMenuOpen={isMenuOpen} />} />
        <Route exact path="/page2" element={<CarPage />} />
        <Route exact path="/details" element={<DetailsPage />} />
        <Route exact path="/login" element={<LoginForm />} />
        <Route exact path="/register" element={<RegisterForm />} />
        <Route exact path="/otp" element={<OtpCode />} />
        <Route exact path="/car-details" element={< CarDetails />} />
        <Route exact path="/product-search" element={< ProductCrousel />} />
      </Routes>
    </Router>
  )
};
export default App;