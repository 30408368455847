
import React from "react";

const dummyProducts = [
    {
        id: 1,
        description: "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
        image: "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg"
    },
    {
        id: 2,
        description: "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
        image: "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg"
    },
    {
        id: 3,
        description: "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
        image: "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg"
    },
    {
        id: 4,
        description: "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
        image: "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg"
    },
    {
        id: 5,
        description: "Toyota Kirloskar Motor allotted 827-acre land for new manufacturing unit in Maharashtra",
        image: "https://img.etimg.com/thumb/msid-114047434,width-300,height-225,imgsize-4636,resizemode-75/tml-securities-trust-sells-1-14-crore-new-ordinary-shares-of-tata-motors-under-dvr-scheme.jpg",
    },
];

const LatestNews = () => {
    return (
        <>
            <div class="mt-10 mx-16 mb-5">
                <h4 className="text-xl font-bold">Latest News</h4>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 px-4 md:px-8 lg:px-10 mx-auto">
                {dummyProducts.map((product) => (
                    <div
                        key={product.id}
                        className="bg-white border shadow-lg rounded-lg flex flex-col p-1 w-full text-sm cursor-pointer"
                    >
                        <a className="block flex-grow">
                            <div>
                                <div className="overflow-hidden bg-white mx-auto mb-2 rounded-lg">
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="transform hover:scale-125 transition-transform duration-300"
                                        style={{ width: "100%", height: "50%" }}
                                    />
                                </div>
                                <div className="ml-2">
                                    <p className="font-semibold mb-1 text-center">{product.description}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>

        </>
    );
};

export default LatestNews;