import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const dummyData = [
    { id: 1, name: 'Car1', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60?w=1920&q=60' },
    { id: 2, name: 'Car2', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1723185624.jpg?w=600&q=60?w=1920&q=60' },
    { id: 3, name: 'Car3', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1701771919.jpg?w=600&q=60?w=1920&q=60' },
    { id: 4, name: 'Car4', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1688711897.png?w=600&q=60?w=1920&q=60' },
    { id: 5, name: 'Car5', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1724753646.jpg?w=600&q=60?w=1920&q=60' },
    { id: 6, name: 'Car2', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1723185624.jpg?w=600&q=60?w=1920&q=60' },
    { id: 7, name: 'Car3', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1701771919.jpg?w=600&q=60?w=1920&q=60' },
    { id: 8, name: 'Car4', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1688711897.png?w=600&q=60?w=1920&q=60' },
    { id: 9, name: 'Car5', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1724753646.jpg?w=600&q=60?w=1920&q=60' },
    { id: 10, name: 'Car1', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456356.jpg?w=600&q=60?w=1920&q=60' },

    // { id: 6, name: 'Car6', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1703546789.jpg?w=600&q=60?w=1920&q=60' },
    // { id: 7, name: 'Car7', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1723456389.jpg?w=600&q=60?w=1920&q=60' },
    // { id: 8, name: 'Car8', img: 'https://images.91wheels.com/assets/b_images/main/models/profile/profile1723185600.jpg?w=600&q=60?w=1920&q=60' },
];

const CategoryProduct = ({ isMenuOpen }) => {
    const [startIndex, setStartIndex] = useState(0);

    const handleNext = () => {
        if (startIndex + 1 < dummyData.length) {
            setStartIndex(prevIndex => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(prevIndex => prevIndex - 1);
        }
    };

    return (
        <section className={`${isMenuOpen ? 'block' : 'hidden'}`}>
            <div className="w-full rounded-3xl shadow-lg bg-gray-200 z-10"> {/* Ensure z-index here is lower than menu */}
                <div className="flex justify-between px-3 py-3">
                    <div className="text-sm md:text-xl font-bold">Hey, What are you looking for?</div>
                    <div>
                        <a href="#" className="text-sm hover:underline">View All</a>
                    </div>
                </div>

                <div className="relative">
                    <FaChevronLeft
                        className={`absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer bg-transparent text-black ${startIndex === 0 ? 'hidden' : ''}`}
                        onClick={handlePrev}
                    />

                    <div className="flex overflow-hidden">
                        {dummyData.slice(startIndex).map((item) => (
                            <div key={item.id} className="px-2 flex-shrink-0 w-[103px] md:w-40">
                                <div className={`overflow-hidden rounded-2xl bg-white shadow-lg`}>
                                    <img src={item.img} alt={item.name} className="w-full object-cover" />
                                </div>
                                <p className="mt-2 text-center text-sm mb-5">{item.name}</p>
                            </div>
                        ))}
                    </div>

                    <FaChevronRight
                        className={`absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer bg-transparent text-black ${startIndex + 1 >= dummyData.length ? 'hidden' : ''}`}
                        onClick={handleNext}
                    />
                </div>
            </div>
        </section>
    );
};


export default CategoryProduct;
