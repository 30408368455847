import React from "react";

const Hero = () => {
    return (
        <>
            {/* Hero Section */}
            <section className="w-full px-2">
                <div
                    className="w-full h-[200px] md:h-[350px] mt-40 md:mt-0"
                >
                    <img
                        src="https://images.unsplash.com/photo-1494783367193-149034c05e8f"
                        alt="Hero Background"
                        className="w-full h-full object-cover rounded-3xl"
                    />
                </div>
            </section>
        </>
    );
};

export default Hero;