import React, { useState } from 'react';
import { GrCar } from "react-icons/gr";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const services = [
    { id: 1, name: "RTO & Challan Check", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 2, name: "Used Auto Buy & Sell", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 3, name: "Personal & Auto Loan", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 4, name: "Auto Parts & Accessories", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 5, name: "Car Wash & Detailing", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 6, name: "Tire Change & Repair", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 7, name: "Roadside Assistance", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 8, name: "Insurance Services", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 9, name: "Vehicle Inspection", icon: <GrCar className="w-24 text-5xl mt-5" /> },
    { id: 10, name: "Car Financing", icon: <GrCar className="w-24 text-5xl mt-5" /> },
];

const OurServices = () => {
    const [startIndex, setStartIndex] = useState(0);
    const itemsToShow = 6; // Number of icons to show at a time

    const handleNext = () => {
        if (startIndex + itemsToShow < services.length) {
            setStartIndex(prevIndex => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex(prevIndex => prevIndex - 1);
        }
    };

    return (
        <section>
            <div className="w-full rounded-3xl shadow-lg bg-orange-500 text-white mt-5 pb-10">
                <div className="flex justify-between px-10 py-5">
                    <div className="text-2xl font-bold">Our Services</div>
                    <div>
                        <a href="#" className="text-sm font-bold">View All</a>
                    </div>
                </div>

                <div className="relative">
                    <FaChevronLeft
                        className={`absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-black ${startIndex === 0 ? 'hidden' : ''}`}
                        onClick={handlePrev}
                    />
                    
                    <div className="flex space-x-2 lg:space-x-5 px-2 lg:px-10 overflow-hidden"> {/* Prevents overflow of icons */}
                        {services.slice(startIndex, startIndex + itemsToShow).map(service => (
                            <div key={service.id} className="overflow-hidden rounded-md bg-white shadow-lg text-black h-32 md:h-44 md:p-5 flex flex-col items-center flex-shrink-0 w-24 md:w-32 lg:w-40"> {/* Responsive width */}
                                {service.icon}
                                <p className="mt-2 mx-1 text-center text-xs lg:text-sm font-bold">{service.name}</p> {/* Responsive text size */}
                            </div>
                        ))}
                    </div>

                    <FaChevronRight
                        className={`absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-black ${startIndex + itemsToShow >= services.length ? 'hidden' : ''}`}
                        onClick={handleNext}
                    />
                </div>
            </div>
        </section>
    );
};

export default OurServices;
