import React from "react";

const ProductCrousel = () => {
    return (
        <>
            <div className="bg-gray-100 flex justify-center items-center min-h-screen">
                {/* Hero Section */}
                <div className="w-full p-2">
                    <div className="bg-black rounded-lg overflow-hidden shadow-lg">
                        {/* Background Image */}
                        <div 
                            className="relative h-52 md:h-60 bg-cover bg-center"
                            style={{ backgroundImage: "url('path/to/your-car-image.jpg')" }}
                        >
                        </div>

                        {/* Input and Button Section */}
                        <div className="p-4 py-10 bg-black space-y-4 md:space-y-0 md:flex md:space-x-10 md:mx-[30%]">
                            {/* Input Placeholder */}
                            <div className="h-12 w-full md:flex-1 rounded bg-gray-300"></div>

                            {/* Button Placeholder */}
                            <div className="h-12 w-full md:w-1/2 rounded bg-orange-500"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductCrousel;
