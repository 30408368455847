import React from 'react';
import { FaRegUser } from "react-icons/fa";

const Login = () => {
    return (
        <>
            {/* Login Link */}
            <div  className="">
                <span className='text-3xl'><FaRegUser/></span>
                <p className='text-sm'>Login</p>
            </div>
        </>
    )
}

export default Login;



