import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";


const LoginForm = () => {
    return (
        <>
            <div className="font-[sans-serif] mt-10">
                <div className="min-h-screen flex fle-col items-center justify-center py-6 px-4">
                    <div className="grid md:grid-cols-2 items-center gap-4 lg:gap-72 max-w-6xl w-full">
                        <div className="lg:h-[400px] md:h-[300px]">
                            <img
                                src="https://res.cloudinary.com/djx69owjm/image/upload/v1730717023/Login-amico_xa12yt.png"
                                className="w-full h-full max-md:w-4/5 mx-auto block object-cover" alt="Dining Experience" />
                        </div>
                        <div className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
                            <form className="space-y-4">
                                <div className="mb-8">
                                    <h3 className="text-gray-800 text-2xl font-semibold">Welcome Back!</h3>
                                    <p className="text-gray-500 text-sm leading-relaxed">Enter Mobile Number to Start Our App</p>
                                </div>

                                <div>
                                    <div className="relative flex items-center">
                                        <FaPhoneAlt
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true" // hides the icon from screen readers
                                        />
                                        <input
                                            name="username"
                                            type="text"
                                            required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-150 ease-in-out"
                                            placeholder="Mobile Number"
                                            aria-label="User name" // adds accessibility for screen readers
                                        />
                                    </div>

                                </div>
                                <div>
                                    <div className="relative flex items-center">
                                        <IoIosLock
                                            className="text-gray-400 w-[18px] h-[18px] absolute left-4 cursor-pointer"
                                            aria-hidden="true" // hides the icon from screen readers
                                        />
                                        <input name="password" type="password" required
                                            className="w-full text-sm text-gray-800 border border-gray-300 px-10 py-3 rounded-lg outline-blue-600"
                                            placeholder="Enter your password" />
                                    </div>
                                </div>


                                <div className="text-sm flex float-end pb-8">
                                    <a href="#" className="text-orange-600 hover:underline font-semibold">
                                        Forgot your password?
                                    </a>
                                </div>


                                <div className="">
                                    <button type="button" className="w-full shadow-xl py-3 px-4 text-sm font-bold tracking-wide rounded-lg text-white bg-orange-600 hover:bg-orange-700 focus:outline-none">
                                        Sign In
                                    </button>
                                </div>

                                <p className="text-sm text-center text-gray-800">Don't have an account <a href="javascript:void(0);" className="text-orange-600 font-semibold hover:underline ml-1 whitespace-nowrap">Sign Up</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default LoginForm;